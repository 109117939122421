@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.ui {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
}

.ui-header {
  height: 0;
  display: flex;
  justify-content: flex-end;
  h3 {
    font-weight: 400;
    font-size: 2.8rem;
    padding: 1rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    border-radius: 0.25rem;
    margin: 0;
    display: inline-block;
    text-shadow: 2px 3px 2px #DE3249;
    user-select: none;
  }
}

.ui-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: visible;

  .action-links {
    float: right;
  }
}

@media (orientation: landscape) {
  .ui-portrait-mode {
    display: none;
    visibility: hidden;
  }
}

@media (orientation: portrait) {
  .ui-portrait-mode {
    display: flex;
    visibility: visible;
  }
}

.ui-portrait-mode {
  background: #061639;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;

  .text-prompt {
    padding: 1rem;
    padding-top: 2rem;
    text-align: center;
    opacity: 0.8;
    h2 {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
    small {
      opacity: 0.6;
      display: block;

    }
  }
  
  .device-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    @keyframes rotateElement {
      0%   {transform: rotate(0deg);}
      25%  {transform: rotate(0deg);}
      75%  {transform: rotate(-90deg)}
      100% {transform: rotate(-90deg)}
    }

    svg {
      width: 60%;
      animation-duration: 1s;
      animation-name: rotateElement;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}

.ui-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 500;
  // background: rgba(0, 0, 0, 0.5);

  .ui-overlay__window {
    background: rgb(255, 255, 255);
    position: absolute;
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
    border-radius: 0.25rem;
    box-shadow: 10px 10px 1rem #000;

    display: flex;
    flex-direction: column;
    color: #000;
  }

  .ui-overlay__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // z-index: 500;
    background: rgba(0, 0, 0, 0.5);

  }

  .ui-overlay__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;

    .action-link {
      display: inline-block;
      min-width: 2rem;
    }
  }

  .ui-overlay__header {
    h2 {
      padding: 0 1.5rem;
      font-weight: 400;
    }
  }

  .ui-overlay__body {
    flex: 1;
    padding: 0 1.5rem;
    // .generic-text {
    // }
  }
}

ul.action-links {
  list-style-type: none;
  padding: 0;
  padding-top: 1.45rem;
  position: relative;
  display: flex;

  li {
    padding-right: 1rem;

    a {
      position: relative;
      display: inline-block;
      color: rgba(255, 255, 255, 0.3);

      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }

      svg {
        width: 2rem;
        height: 2rem;
        display: inline-block;
      }
      span {
        position: absolute;
        bottom: -1em;
        left: 0;
        display: inline-block;
        visibility: hidden;
      }
    }
  }
  color: #fff;
}