.xylophone {
  height: 100%;
  // touch-action: manipulation;
  .bars {
    
  }

  // .pixi-container {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }
}