body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: sans-serif;

  // Prevent pinch/zoom
  touch-action: none;
  
}

*, *:before, *:after {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
  width: 100vw;
  touch-action: manipulation;
}

.app {
  width: 100%;
  height: 100%;
  touch-action: manipulation;
}

.instrument-active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.ui {
  z-index: 100;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}