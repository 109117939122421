.state-viewer {
  display: flex;
  padding-left: 4.5rem;
  background: rgba(255, 255, 255, 0.233);
  
  .state-viewer-toggle {
    height: 4rem;
    width: 4rem;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}